import { INotificationTabDTO } from '@modules/home/types/INotificationTabDto';
import Network from '@utils/network/Network';
import { IGeneralLoginResultDTO } from '../types/IGeneralLoginResultDTO';
import { ILoginDTO } from '../types/ILoginDTO';

/**
* Personnels için oturum açma yöntemi. Bu bitiş noktası herhangi bir istek için erişilebilir.
* @url `/api/v1.0/Account/Personnel/SignIn`
*/
export function personnelLogin(LoginDTO: ILoginDTO) {

  return Network.postRequest<IGeneralLoginResultDTO>({
    url: '/Account/Login/GeneralInfo',
    data: LoginDTO,
    successMessaging: false,
  });

}

/**
 * Personeller için çıkış işlemi. Bu endpoint sadece personeller için erişilebilirdir.
 * @url `/api/v1.0/Account/LogOut`
*/
export function personnelSignOut(data: any) {

  return Network.putRequest({
    url: '/Account/LogOut',
    data,
    errorMessaging: false,
    successMessaging: false,
    rejectable: true
  });

}

export function getNotificationsAPI() {

  return Network.getRequest<INotificationTabDTO[]>({
    url: '/Account/Notifications',
    errorMessaging: false,
    backDrop: false,
    successMessaging: false,
  });

}
