import { SystemLang } from '@assets/enums/SystemLang';
import Router from 'next/router';

export default function GetSystemLang() {

  switch (Router.locale) {
    case 'tr': return SystemLang.Turkish;
    case 'en': return SystemLang.English;
    default: return SystemLang.Turkish;
  }

}
