/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const Svg = (props: any) => (
  <svg
    {...props}
    viewBox="0 0 82 82"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <path
        fillRule="evenodd" clipRule="evenodd"
        d="M4 37C4 16.5655 20.5655 0 41 0C61.4347 0 78 16.5655 78 37C78 57.4347 61.4347 74 41 74C20.5655 74 4 57.4347 4 37ZM46.2671 21.4072C45.259 20.3991 43.6248 20.3991 42.6166 21.4072C41.6085 22.4153 41.6085 24.0498 42.6166 25.0579L50.2562 32.6977H23.7907C22.365 32.6977 21.2093 33.8535 21.2093 35.2791C21.2093 36.7047 22.365 37.8605 23.7907 37.8605H50.7997L42.4993 47.3466C41.5603 48.4194 41.6691 50.0505 42.7419 50.9891C43.8148 51.928 45.4459 51.8193 46.3844 50.7464L58.431 36.979C59.3258 35.9561 59.2746 34.4148 58.3136 33.4538L46.2671 21.4072Z" fill="#9F414B"
      />
    </g>
    <defs>
      <filter
        id="filter0_d" x="0"
        y="0" width="82"
        height="82" filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha" type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.623529 0 0 0 0 0.254902 0 0 0 0 0.294118 0 0 0 0.25 0" />
        <feBlend
          mode="normal" in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal" in="SourceGraphic"
          in2="effect1_dropShadow" result="shape"
        />
      </filter>
    </defs>
  </svg>


);
export default Svg;
