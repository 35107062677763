/* eslint-disable @typescript-eslint/no-empty-function */

import { personnelLogin, personnelSignOut } from '@modules/app/api/AccountApi';
import UserService from '@services/UserService';
import RoutingService from '@services/RoutingService';
import { CookieService } from '@services/CookieService';
import { CookieEnum } from '@assets/enums/CookieEnum';
import { i18n } from 'next-i18next';
import deleteAllCookies from '@helpers/deleteAllCookies';
import * as $AC from './AppActions';
import { ILoginDTO } from '../types/ILoginDTO';

export async function sigInUser(dto: ILoginDTO) {

  $AC.updateAppReducer({ businessId: dto?.institutionId });

  try {

    CookieService.setCookie(CookieEnum.BusinessId, dto?.institutionId.toString(), 1);

  } catch (error) {

    console.log(error);

  }

  await personnelLogin(dto)
    .then(async ({ result: { loginResult, tenancyInfo, userInfo } }) => {

      UserService.signin({ business: tenancyInfo, personnel: userInfo, tokens: loginResult.token });

      $AC.showToast(i18n?.t('loginSuccess') || '');

      RoutingService((routes, router) => router.push(routes.home));

    });

}

export const signOutUser = async () => {

  deleteAllCookies();

  const NotificationInfoDTO = { pushToken: undefined, oS: undefined };

  await personnelSignOut(NotificationInfoDTO)
    .then(() => {})
    .catch(() => {});

  RoutingService((routes, router) => router.push(routes.index));


};
