/* eslint-disable @next/next/no-img-element */
/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Hidden from '@material-ui/core/Hidden';
import { useForm } from 'react-hook-form';
import { sigInUser } from '@modules/app/redux/AccountActions';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import BranchSvg from '@assets/svg/BranchSvg';
import GotoSiteSvg from '@assets/svg/GotoSiteSvg';
import LockIcon from '@material-ui/icons/Lock';
import OpsTextInput from '@components/form/OpsTextInput';
import type { GetServerSidePropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import nextI18nextConfig from 'next-i18next.config';
import RoutingService, { Routes } from '@services/RoutingService';
import ParseCookies from '@helpers/ParseCookies';
import GetSystemLang from '@helpers/GetSystemLang';
import { CookieEnum } from '@assets/enums/CookieEnum';

export default function LoginPage() {

  const { register, handleSubmit, errors } = useForm({ mode: 'all', reValidateMode: 'onChange', });

  const { t } = useTranslation();

  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const { palette: { primary: { main: primaryMain } } } = useTheme();

  const confirm = useCallback(({ userName, password, institutionId }: any) => {

    sigInUser({
      userName: userName || '',
      password: password || '',
      institutionId: institutionId || '',
      currentLanguage: GetSystemLang()
    });

  }, []);

  const goForgorPasswordPage = useCallback(() => RoutingService((routes, router) => router.push(routes.forgotPassword)), []);

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      alignItems="center"
    >

      <Hidden smDown>
        <div className="next-image-container">
          <img
            src="/homeBg.png"
            alt=""
            className="next-image-container-img"
          />
        </div>
      </Hidden>

      <Hidden mdDown>
        <div className={classes.winTop}>
          <div className="next-image-container">
            <img
              src="/wingTop.png"
              alt=""
              className="slide-in-tr next-image-container-img"
            />
          </div>
        </div>

        <div className={classes.winBottom}>
          <div className="next-image-container">
            <img
              src="/wingBottom.png"
              alt=""
              className="slide-in-bl next-image-container-img"
            />
          </div>
        </div>
      </Hidden>

      <div className={classes.copyright}>
        <img
          src="/milvasoft.png"
          width="27"
          height="27"
          alt="Milvasoft Logo"
          loading="eager"
        />
        Copyright © Milvasoft Yazılım Bilişim Ar-Ge Dan. San. ve Tic. Ltd.ŞTİ.
      </div>

      <div className={classes.snackBar}>

        <div className={classes.opsiyonContainer}>
          {t('indexSlogan')}
        </div>

        <div className={classes.webSite}>
          {t('visitSite')}
        </div>

        <a href="https://www.opsiyonerp.com/" target="_blank" rel="noreferrer">
          <GotoSiteSvg
            fill={primaryMain}
            width="50"
            height="50"
            style={{ cursor: 'pointer' }}

          />
        </a>

      </div>

      <Grid
        item
        xs={8} sm={6}
        md={4} lg={3}
      >
        <div className={classes.opsLogo}>
          <img
            src="/loginMobilOpsLogo.png"
            alt="OPS!YON LOGO"
            style={{ objectFit: 'contain', width: '100%', height: '100%' }}
          />
        </div>

        <h1 className={`${classes.opsiyonName} scale-in-center`}>OPS!YON</h1>

        <form
          onSubmit={handleSubmit(confirm)}
          className={`${classes.form} scale-in-center`}
        >

          <OpsTextInput
            id="institutionId"
            name="institutionId"
            label={t('indexPage.institution')}
            placeholder={t('indexPage.institution')}
            defaultValue={process.env.NODE_ENV === 'development' ? 'opsiyon_1' : ''}
            inputRef={register({ required: true, maxLength: 250 })}
            helperText={errors.institutionId && t('helperTexts.characterLimitRequired', { count: 250 })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BranchSvg fill="#3C4043" />
                </InputAdornment>
              ),
            }}
          />

          <OpsTextInput
            id="userName"
            name="userName"
            defaultValue={process.env.NODE_ENV === 'development' ? 'opsiyonTest' : ''}
            label={t('indexPage.userName')}
            placeholder={t('indexPage.userName')}
            inputRef={register({ required: true, maxLength: 250 })}
            helperText={errors.userName && t('helperTexts.characterLimitRequired', { count: 250 })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BranchSvg fill="#3C4043" />
                </InputAdornment>
              ),
            }}
          />

          <OpsTextInput
            id="password"
            name="password"
            defaultValue={process.env.NODE_ENV === 'development' ? 'opsiyonTest-!' : ''}
            type={showPassword ? 'text' : 'password'}
            label={t('indexPage.password')}
            placeholder={t('indexPage.password')}
            inputRef={register({ required: true, maxLength: 250 })}
            helperText={errors.password && t('helperTexts.characterLimitRequired', { count: 250 })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon style={{ color: '#3C4043' }} fontSize="large" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.loginButton}
          >
            {t('indexPage.login')}
          </Button>

          <Button color="secondary" style={{ textTransform: 'none' }} onClick={goForgorPasswordPage}>
            {t('indexPage.forgotPassword')}
          </Button>

        </form>

      </Grid>

    </Grid>
  );

}

const useStyles = makeStyles((theme) => ({

  root: {
    width: '100%',
    height: '100%',
    background: '#fff',
    position: 'relative',
    minHeight: 600,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      minHeight: 700,
    }
  },

  homeBg: {
    filter: 'blur(4px)'
  },

  opsLogo: {
    position: 'relative',
    width: '100%',
    height: 200,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    }
  },

  winTop: {
    position: 'absolute',
    width: '60%',
    height: '60%',
    left: '37%',
    top: '8%',
  },

  winBottom: {
    position: 'absolute',
    width: '60%',
    height: '60%',
    left: '3%',
    bottom: '5%',
  },

  copyright: {
    position: 'absolute',
    left: '1%',
    bottom: '1%',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'

  },

  snackBar: {
    position: 'absolute',
    right: '1%',
    bottom: '1%',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '0.5rem !important',
    padding: 10,
    textAlign: 'center',
    width: '25%',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      bottom: 50,
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '25%',
    },

  },

  opsiyonContainer: {
    flex: 2,
    color: 'white'
  },

  opsiyon: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },

  webSite: {
    flex: 1,
    color: 'white',
    marginRight: '20px'
  },

  form: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    minHeight: '320px',
    width: '80%',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      marginTop: 20,
      width: '100%',
    }
  },

  opsiyonName: {
    width: '100%',
    textAlign: 'center',
    margin: '5% 0%',
    fontSize: '4.5rem',
    fontFamily: '\'Sarpanch\', sans-serif !important',
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },

  loginButton: {
    color: 'white',
    width: '60%',
    alignSelf: 'center',
    borderRadius: 10,
    padding: 12,
    fontSize: '1.3rem !important',
    textTransform: 'none',
    fontWeight: 'bold',
    marginTop: 10,
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.primary.main
    }
  },

}));

export async function getServerSideProps(context: GetServerSidePropsContext) {

  try {

    const cookies = ParseCookies(context.req);

    const opsiyon = cookies?.[CookieEnum.Opsiyon] ? JSON.parse(cookies?.[CookieEnum.Opsiyon]) : undefined;

    if (opsiyon?.tokens?.accessToken !== undefined && cookies?.businessId !== undefined && opsiyon?.personnel !== undefined) {

      return {
        props: {
          ...(await serverSideTranslations(context?.locale || 'en', ['common'], nextI18nextConfig)),
          locale: context.locale,
        },
        redirect: {
          destination: `/${context.locale}${Routes.home}`,
        },
      };

    }

    return {
      props: {
        ...(await serverSideTranslations(context?.locale || 'en', ['common'], nextI18nextConfig)),
        locale: context.locale
      },
    };

  } catch (e) {

    return {
      props: {
        errorCode: e?.response?.status || 500,
        error: e,
        ...(await serverSideTranslations(context?.locale || 'en', ['common'], nextI18nextConfig)),
        locale: context.locale
      }
    };

  }

}
